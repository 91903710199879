import React, { useEffect, useRef } from "react";
import { useForm, Link, usePage } from "@inertiajs/inertia-react";
// components
import TextInput from "Components/TextInput";
import PrimaryButton from "Components/PrimaryButton";
import Checkbox from "Components/Checkbox";
import translate from "Helpers/Translation";

// views

type Props = {
    status: string;
    canResetPassword: boolean;
};

export default function Login({ status, canResetPassword }: Props) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    });
    const [language] = React.useState<string>(usePage().props.language);

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const onHandleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: "email" | "password" | "remember",
        type: "text" | "checkbox" = "text"
    ) => {
        setData(field, type === "checkbox" ? e.target.checked : e.target.value);
    };

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        post(route("login.auth"));
    };

    return (
        <>
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full bg-gradient bg-no-repeat bg-full"
                        // style={{
                        //   backgroundImage: "url(" + bg + ")",
                        // }}
                    ></div>
                    <>
                        <div className="absolute right-2 bottom-2 text-white text-sm">
                            v.1.0.1
                        </div>
                        <div className="container mx-auto px-4 h-full">
                            <div className="flex content-center items-center justify-center h-full">
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                                        <img
                                            className="mx-auto mt-5 mb-5 relative z-999"
                                            width="180"
                                            src="../../images/logo.jpg"
                                        />
                                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                            {status && (
                                                <div className="mb-4 font-medium text-sm text-grey">
                                                    {status}
                                                </div>
                                            )}
                                            <form onSubmit={submit}>
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-900 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Email
                                                    </label>
                                                    <TextInput
                                                        autoComplete="email"
                                                        type="email"
                                                        className="border-1 px-3 py-3 placeholder-gray-300 text-black bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Email"
                                                        value={data.email}
                                                        handleChange={(e) =>
                                                            onHandleChange(
                                                                e,
                                                                "email"
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-900 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        {translate(language, "Password")}
                                                    </label>
                                                    <TextInput
                                                        type="password"
                                                        className="border-1 px-3 py-3 placeholder-gray-300 text-black bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        placeholder="Senha"
                                                        value={data.password}
                                                        handleChange={(e) =>
                                                            onHandleChange(
                                                                e,
                                                                "password"
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <label className="inline-flex items-center cursor-pointer">
                                                        <Checkbox
                                                            name="remember"
                                                            handleChange={(e) =>
                                                                onHandleChange(
                                                                    e,
                                                                    "remember",
                                                                    "checkbox"
                                                                )
                                                            }
                                                        />
                                                        <span className="ml-2 text-sm font-semibold text-gray-900">
                                                            {translate(language, "Remember me")}
                                                        </span>
                                                    </label>
                                                </div>
                                                {errors && (
                                                    <div className="mb-4 font-medium text-sm text-gray-900">
                                                        {errors.email}
                                                    </div>
                                                )}

                                                <div className="text-center mt-6">
                                                    <PrimaryButton
                                                        className="ml-4"
                                                        processing={processing}
                                                    >
                                                        {translate(language, "Log in")}
                                                    </PrimaryButton>
                                                </div>
                                                <a
                                                    href={route(
                                                        "password.request"
                                                    )}
                                                    className="text-gray-900"
                                                >
                                                    <small>
                                                        {translate(language, "Forgot your password?")}
                                                    </small>
                                                </a>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </section>
            </main>
        </>
    );
}
